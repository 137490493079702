import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import queryString from 'query-string-es5'
import MoreInfo from '../../../src/containers/MoreInfoForm'
import ConfirmationImg from '../images/confirmation.jpeg'
import { graphql } from 'gatsby'

const postForm = {}
const lawyerType = ''

const MoreInfoWrapper = styled.div`
    width: 60%;
    margin: 20px auto;
    > button {
        margin-top: 45px;
        margin-left: 30px;
    }
    @media (max-width: 768px) {
        width: 100%;
        > button {
            margin-left: 0;
        }
    }
`

const Table = styled.table`
    margin: 15px auto;
    max-width: 400px;
    td,
    th {
        border: 1px solid lightgrey;
    }
    tr {
        :nth-child(even) {
            background-color: #ecebeb;
        }
        th {
            background-color: lightgrey;
        }
    }

    td {
        padding: 5px;
    }
    th {
        padding: 10px 5px;
    }
`

const Confirmation = ({ location }) => {
    const { lawyerType, uuid } = queryString.parse(location.search)

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            typeof window.gtag !== 'undefined'
        ) {
            window.gtag('event', 'conversion', {
                send_to: 'AW-996648267/NuPCCIqx6l0Qy8qe2wM',
                transaction_id: uuid,
            })
        }
    })

    return (
        <Layout location={location} title="Confirmation - Soumissions Avocat">
            <main>
                <h1>Confirmation</h1>
                <h2>
                    Confirmation de votre demande pour{' '}
                    <strong style={{ color: 'blue' }}>
                        trouver un avocat compétent
                    </strong>{' '}
                    qui pourra vous{' '}
                    <strong style={{ color: 'green' }}>
                        aider à régler votre affaire efficacement
                    </strong>
                    !
                </h2>
                <p>
                    Vous recevrez par courriel la confirmation de votre demande
                    dans les prochaines minutes.
                </p>
                <MoreInfoWrapper>
                    <MoreInfo
                        lawyerType={lawyerType}
                        uuid={uuid}
                        customImg={ConfirmationImg}
                    />
                </MoreInfoWrapper>
                <p>
                    <strong>Vous êtes pris en charge!</strong>
                </p>

                <h2>Quelles sont les prochaines étapes?</h2>
                <p>
                    Nous acheminons immédiatement votre demande à{' '}
                    <strong>JuriGo.ca</strong>, notre partenaire de confiance
                    qui sélectionne pour vous l’avocat qui répond le mieux à vos
                    besoins.
                </p>
                <p>
                    Dans le cas où l’information fournie ne nous permettrait pas
                    d’identifier le bon avocat pour vous, notre équipe service
                    client et conseillers en réussite vous contacterons pour
                    clarifier votre demande.
                </p>
                <h2>Comment ça fonctionne?</h2>
                <p>
                    <strong style={{ color: 'blue' }}>
                        L’avocat vous contactera rapidement pour discuter de vos
                        besoins,
                    </strong>{' '}
                    répondre à toutes vos questions et vous offrir ses services.
                    Vous serez ensuite libre de décider de poursuivre ou non
                    votre démarche avec lui.
                </p>
                <h2>Qui est JuriGo.ca ?</h2>
                <p>
                    JuriGo.ca est un{' '}
                    <strong style={{ color: 'green' }}>
                        service intelligent de référencement juridique
                    </strong>{' '}
                    qui vous aide à obtenir les services d’un avocat compétent
                    dans le domaine de droit qui vous concerne. JuriGo.ca
                    répertorie un grand nombre d’avocats et notaires vérifiés
                    partout au Québec. Lorsque vous faites une demande de
                    soumissions sur Soumissions Avocat, votre demande est
                    acheminée à JuriGo.ca qui s’occupe de sélectionner un avocat
                    qualifié de votre secteur pour répondre à votre demande. Ne
                    vous étonnez donc pas si l’avocat qui vous contacte se
                    présente comme appartenant à JuriGo.ca.
                </p>
                <h2>Questions fréquentes des utilisateurs</h2>
                <h3>Comment savoir si je suis éligible à l’aide juridique ?</h3>
                <Table>
                    <thead>
                        <tr>
                            <th colspan="100%">
                                <strong>
                                    Barème des revenus annuels(bruts)*
                                </strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Personne seule </td>
                            <td>
                                <strong>22 750$</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Adulte + 1 enfant</td>
                            <td>27 834$</td>
                        </tr>
                        <tr>
                            <td>Adulte + 2 enfants ou plus</td>
                            <td>29 714 $</td>
                        </tr>
                        <tr>
                            <td>Conjoints</td>
                            <td>31 661 $</td>
                        </tr>
                        <tr>
                            <td>Conjoints + 1 enfant</td>
                            <td>35 424 $</td>
                        </tr>
                        <tr>
                            <td>Conjoints + 2 enfants ou plus</td>
                            <td>37 306 $</td>
                        </tr>
                    </tbody>
                </Table>
                <h3>Quels sont les tarifs d’un avocat ?</h3>
                <p>
                    Les tarifs varient d’un avocat à l’autre, selon le domaine
                    de pratique, l’expérience et le territoire. Certains avocats
                    offrent un tarif spécial pour la première rencontre.
                    D’autres, des forfaits pré-déterminés en fonction de votre
                    dossier. La majorité offre toutefois leur service suivant un
                    taux horaire qui peut varier entre 130 $ et 400 $, selon
                    l’avocat. Pour éviter toute surprise, il est donc primordial
                    de traiter de la question des honoraires dès le premier
                    contact avec votre avocat.
                </p>
                <p>
                    N’hésitez pas à nous contacter si vous avez d’autres
                    questions : info@jurigo.ca.
                </p>
                <p>
                    <strong style={{ fontSize: '1.5rem' }}>
                        Merci de faire confiance à Soumissions Avocat !
                    </strong>
                </p>
            </main>
        </Layout>
    )
}

export default Confirmation

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
